import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig';
import './DatosAbiertos.css';
import Word from '../../Images/word.png'
import Excel from '../../Images/excel.png'
import PDF from '../../Images/PDF.png'
import Otro from '../../Images/otro.png'

const DatosAbiertos = () => {
    const [documentos, setDocumentos] = useState([]);

    useEffect(() => {
        const fetchDocumentos = async () => {
            try {
                const response = await axios.get('getDocumentos.php'); // Ajusta a tu endpoint correcto
                setDocumentos(response.data);
            } catch (error) {
                console.error('Error al cargar los documentos:', error);
            }
        };
        fetchDocumentos();
    }, []);

    const obtenerIcono = (nombreArchivo) => {
        const extension = nombreArchivo.split('.').pop().toLowerCase();
        switch (extension) {
            case 'doc':
            case 'docx':
                return Word; 
            case 'xls':
            case 'xlsx':
            case 'csv':
                return Excel; 
            case 'pdf':
                return PDF; 
            default:
                return Otro; 
        }
    };

    const renderizarDocumentos = () => {
        return documentos.map((documento, index) => (
            <div className="tarjeta-documento" key={index}>
                <a href={`${axios.defaults.baseURL}Documentos/Datos Abiertos/${documento}`} target="_blank" rel="noopener noreferrer">
                    <img src={obtenerIcono(documento)} alt="Icono del archivo" />
                    <p>{documento}</p>
                </a>
            </div>
        ));
    };

    return (
        <div className='Fondo-datos'>
            <div className="datos-abiertos-intro">
                <h1>Datos Abiertos</h1>
                <p>El DIF de Zinacantepec ponen a su disposición, datos abiertos con las características técnicas necesarias para ser usados, reutilizados y redistribuidos libremente por cualquier persona; información que puede ser de utilidad, para realizar investigaciones, gráficos o desarrollar ideas innovadoras que puedan generar beneficios sociales y económicos, mejorando la calidad de vida de las personas.</p>
            </div>
            <div className="datos-abiertos-container">
                {renderizarDocumentos()}
            </div>
        </div>
    );
};

export default DatosAbiertos;
