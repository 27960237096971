import React, { useState } from 'react';
import './DirectorioCard.css'; // Asegúrate de tener este archivo con los estilos necesarios

const DirectorCard = ({ name, image, email, position, facebook }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div className="card-container" onClick={handleClick}>
            <div className={`card ${isFlipped ? 'flipped' : ''}`}>
                <div className="card-front">
                    <img src={image} alt={name} />
                    <div className="card-text-container">
                        <h4>{name}</h4>
                        <h6>{position}</h6>
                    </div>
                </div>
                <div className="card-back">
                    <img src={image} alt='foto_reverso'/>
                    <div className="card-text-container">
                        <h5>{name}</h5>
                        <p>Email: {email}</p>
                        {facebook && <p>Facebook: {facebook}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DirectorCard;
