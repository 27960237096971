import React from 'react';
import DirectorCard from '../DirectorioCard/DirectorioCard.js';
import './Directorio.css';
import presidenta from '../../../Images/Directorio/presidenta.png'
import director from '../../../Images/Directorio/director.png'
import tesorero from '../../../Images/Directorio/tesorero.png'
import organo from '../../../Images/Directorio/contralora.png'
import uipe from '../../../Images/Directorio/uippe.png'
import procuraduria from '../../../Images/Directorio/procuraduria.png'
import uris from '../../../Images/Directorio/uris.png'
import tics from '../../../Images/Directorio/tics.png'
import salud from '../../../Images/Directorio/salud.png'
import nutricion from '../../../Images/Directorio/nutricion.png'
import adultomayor from '../../../Images/Directorio/adultos.png'
import transparencia from '../../../Images/Directorio/transparencia.png'
import archivo from '../../../Images/Directorio/archivo.png'
import comunicacion from '../../../Images/Directorio/comunicacion.png'


const Directorio = () => {
    const directors = [
        {
            name: "Jessica Rios Lara",
            position: "Presidenta Honorífica",
            email: "smdifzdir22.24@gmail.com", 
            image: presidenta,
            facebook:'/JessicaRiosL'
        },
        {
            name: "JUAN PABLO DEL RIO PICHARDO",
            position: "Director",
            email: "smdifzdir22.24@gmail.com",
            image: director
        },
        {
            name: "Fidel Serrano Degollado",
            position: "Tesorero",
            email: "smdifzteso22.24@gmail.com",
            image: tesorero
        },
        {
            name: "MAGALI RAMÍREZ GUTIÉRREZ",
            position: "Organo interno de control",
            email: "smdifzci22.24@gmail.com",
            image: organo
        },
        {
            name: "OCTAVIO CARLOS PORTILLA MARCIAL",
            position: "Unidad de información, planeación programación y evaluación",
            email: "smdifzuippe22.24@gmail.com",
            image: uipe
        },
        {
            name: "Víctor Hugo Madín Ulloa",
            position: "Procuraduría de protección de niñas, niños y adolescentes",
            email: "smdifzppnna22.24@gmail.com",
            image: procuraduria
        },
        {
            name: "ROSA ELENA MÁRQUEZ VÁZQUEZ",
            position: "Coordinación de atención a la discapacidad",
            email: "smdifzcad22.24@gmail.com",
            image: uris
        },
        {
            name: "Jorge Garatachia Contreras",
            position: "Coordinación de tecnologías de la información",
            email: "smdifztics22.24@gmail.com",
            image: tics
        },
        {
            name: "alfonso carrillo iturbe",
            position: "Coordinación de salud, prevención y bienestar familiar",
            email: "smdifzcpbf22.24@gmail.com",
            image: salud
        },
        {
            name: "ariadna michelle palma mejía",
            position: "Coordinación de alimentación y nutrición familiar",
            email: "smdifzcanf22.24@gmail.com",
            image: nutricion
        },
        {
            name: "JUANA SANDOVAL VALDESPINO",
            position: "Coordinación de atención al adulto mayor",
            email: "smdifzcaam22.24@gmail.com",
            image: adultomayor
        },
        {
            name: "benjamín palma paredes",
            position: "Unidad de transparencia",
            email: "smdifzut22.24@gmail.com",
            image: transparencia
        },
        {
            name: "jorge Arturo Cruz García",
            position: "Unidad de Archivo",
            email: "smdifzua22.24@gmail.com",
            image: archivo
        },
        {
            name: "Humberto Valencia Hinojosa",
            position: "Unidad de Comunicación Social",
            email: "smdifzucs22.24@gmail.com",
            image: comunicacion
        }
        
    ];
    

    const presidentaHonorifica = directors.find(director => director.position === "Presidenta Honorífica");
    const otrosDirectores = directors.filter(director => director.position !== "Presidenta Honorífica");

    return (
        <div class="full-screen-container">
            {/* Sección para la Presidenta Honorífica */}
            {presidentaHonorifica && (
                <div className="presidenta-honorifica-container">
                    <DirectorCard
                        name={presidentaHonorifica.name}
                        image={presidentaHonorifica.image}
                        email={presidentaHonorifica.email}
                        facebook={presidentaHonorifica.facebook}
                        position={presidentaHonorifica.position}
                    />
                </div>
            )}

            {/* Sección para los demás directores */}
            <div className="directorio">
                {otrosDirectores.map((director, index) => (
                    <DirectorCard
                        key={index}
                        name={director.name}
                        image={director.image}
                        email={director.email}
                        position={director.position}
                        facebook={director.facebook}
                    />
                ))}
            </div>
        </div>
    );
};

export default Directorio;
