
import React, { useState } from 'react';
import './Somos.css';
import item1 from "../../Images/item1.jpg";
import item2 from "../../Images/item2.jpg";
import item3 from "../../Images/item3.jpg";

const Somos = () => {
  const [selected, setSelected] = useState(null);

  const handleClick = (item) => {
    setSelected(item === selected ? null : item);
  };

  return (
    <div className="somos">
      <div className={`somoscard-container ${selected === "mision" ? 'expanded' : ''}`} onClick={() => handleClick("mision")}>
        <div className={`somoscard ${selected === "mision" ? 'expanded' : ''}`}>
          <h1 className="front">Misión</h1>
          <div className={`contenido ${selected === "mision" ? 'visible' : ''}`}>
            <img src={item1} alt="Misión" />
            <h2 className="titulo">Misión</h2>
            <p>Combatir la desigualdad de oportunidades y las causas que provocan la vulnerabilidad en la niñez, adolescencia y la familia; promoviendo programas de 
              asistencia social de forma eficaz y eficiente, en coordinación con instituciones públicas y privadas a nivel municipal, estatal y federal.</p>
          </div>
        </div>
      </div>

      <div className={`somoscard-container ${selected === "vision" ? 'expanded' : ''}`} onClick={() => handleClick("vision")}>
        <div className={`somoscard ${selected === "vision" ? 'expanded' : ''}`}>
          <h1 className="front">Visión</h1>
          <div className={`contenido ${selected === "vision" ? 'visible' : ''}`}>
            <img src={item2} alt="Visión" />
            <h2 className="titulo">Visión</h2>
            <p>Ser un Organismo Público Descentralizado, equitativo y transparente que coadyuva 
              al mejoramiento de la calidad de vida de los habitantes más vulnerables del municipio.</p>
          </div>
        </div>
      </div>

      <div className={`somoscard-container ${selected === "Objetivo General" ? 'expanded' : ''}`} onClick={() => handleClick("Objetivo General")}>
        <div className={`somoscard ${selected === "Objetivo General" ? 'expanded' : ''}`}>
          <h1 className="front">Objetivo General</h1>
          <div className={`contenido ${selected === "Objetivo General" ? 'visible' : ''}`}>
            <img src={item3} alt="Objetivo General" />
            <h2 className="titulo">Objetivo General</h2>
            <p>Brindar asistencia social orientada a la protección de la infancia, las personas con discapacidad,
               los adultos mayores, los grupos vulnerables y las familias Zinacantepequenses, mediante acciones que contribuyan a mejorar su condición social.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Somos;
