import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from '../../../AxiosConfig.js'; 
import './Carrucel.css';

function Carrusel() {
    const [images, setImages] = useState([]);      
    useEffect(() => {
        // Async function inside useEffect
        const fetchImages = async () => {
            try {
                const response = await axios.get('getCarrusel.php');
                setImages(response.data.usuarios);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchImages();
    }, []);
    
    const settings = {
        autoplay: true,
        autoplaySpeed: 10,
        cssEase: 'linear',
        infinite: true,
        speed: 10000,
        slidesToShow: 3, 
        slidesToScroll: 2,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 1, 
                    slidesToScroll: 1,
                    autoplaySpeed: 4000,
                    cssEase: 'linear',
                    infinite: true,
                    speed: 1000,
                    fade: true, 
                }
            }
        ]
    };
    
    
    
    return (
        <div className='Carrucel'>
            <Slider className='Slider_Body' {...settings}>
                {images.map((image) => (
                    <div className='Carrucel_Container' key={image.id}>
                        <img className='Carrucel_Image' src={`${axios.defaults.baseURL}${image.url}`} alt={`Slide ${image.id}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Carrusel;
