import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Header.css';
import foto from '../../Images/LOGODIF.png'; 
import axios from '../../AxiosConfig';
function Header() {
  return (
    <Navbar expand="lg" className=" header_nav" >
      <Link to="/">
        <img src={foto} alt="Logo" className="navbar_logo" />
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className="navbar-toggler-icon navbar_icon_color"></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="header_ul">
          <Nav.Link className='Navbar_Element' as={Link} to="/">Inicio</Nav.Link>
          <Nav.Link className='Navbar_Element' as={Link} to="/somos">Quienes Somos</Nav.Link>
          <Nav.Link className='Navbar_Element' as={Link} to="/directorio">Directorio</Nav.Link>
          <NavDropdown className='Navbar_Element' title="Transparencia" id="transparenciaDropdown">
            <NavDropdown.Item className='Desplegable_Menu' href="https://www.ipomex.org.mx/ipo3/lgt/indice/DIFZINACANTEPEC.web">
              IPOMEX
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' as={Link} to="/Conac">
              SEVAC
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' href="https://www.saimex.org.mx/saimex/ciudadano/login.page">
              SAIMEX
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' href="https://www.sarcoem.org.mx/sarcoem/ciudadano/login.page">
              SARCOEM
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' href="https://www.plataformadetransparencia.org.mx/">
              Plataforma Nacional de Transparencia
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' as={Link} to="/Avisos">
              Avisos de Privacidad
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' as={Link} to="/Eval2023">
              Evaluación de Programas Presupuestarios e Indicadores de Desempeño 2023
            </NavDropdown.Item>
            <NavDropdown.Item className='Desplegable_Menu' as={Link} to="/Eval2022">
              Evaluación de Programas Presupuestarios e Indicadores de Desempeño 2022
            </NavDropdown.Item>
            <NavDropdown className='Navbar_Element2' title="Procedimientos de Transparencia" id="transparenciaDropdown">
            {/* ... otros items del dropdown ... */}
            <NavDropdown.Divider />
              <NavDropdown.Item className='Desplegable_Menu' href={`${axios.defaults.baseURL}Documentos/Transparencia/ACCESO%20A%20LA%20INFORMACIÓN%20.pdf`} target="_blank">
                Acceso a la Información
              </NavDropdown.Item>
              <NavDropdown.Item className='Desplegable_Menu' href={`${axios.defaults.baseURL}Documentos/Transparencia/DERECHOS%20ARCO.pdf`} target="_blank">
                Derechos ARCO
              </NavDropdown.Item>
          </NavDropdown>
            <NavDropdown.Divider />
            <div className='aviso_header'>
              <p className="header_sub_p">
                Si consideras que el Sistema Municipal DIF de Zinacantepec no cumple con sus obligaciones de transparencia, puedes denunciarlo ante el INFOEM, a través del sitio <a href="https://www.transparenciaestadodemexico.org.mx/denciu/denuncia/insert.page">INFOEM Denuncia</a>; o bien, mediante escrito presentado físicamente ante la Unidad de Transparencia de este Organismo o por correo electrónico dirigido a la Dirección General Jurídica y de Verificación del propio órgano garante.
              </p>
            </div>
          </NavDropdown>
          <Nav.Link className='Navbar_Element' as={Link} to="/contacto">Contacto</Nav.Link>
          <Nav.Link className='Navbar_Element' as={Link} to="/noticias">Noticias</Nav.Link>
          <Nav.Link className='Navbar_Element' as={Link} to="/datos">Datos Abiertos</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;

