import React, { useState } from 'react';
import axios from '../../AxiosConfig';
import Swal from 'sweetalert2';
import Noticias from './Noticias/Noticias.js';
// Importa los otros componentes cuando estén listos para usar
// import Carrusel from './Carrusel';
// import Contacto from './Contacto';
// import Usuarios from './Usuarios';

const Admin = () => {
    const [usuarioAutenticado, setUsuarioAutenticado] = useState(false);
    const [credenciales, setCredenciales] = useState({ username: '', password: '' });
    const [seccionActual, setSeccionActual] = useState('');

    // Manejar cambios en los campos del formulario
    const handleInputChange = (event) => {
        setCredenciales({
            ...credenciales,
            [event.target.name]: event.target.value
        });
    };

    // Función para iniciar sesión
    const iniciarSesion = async () => {
        try {
            console.log(credenciales)
            const response = await axios.post('Login.php', credenciales);
            console.log(response)
            if (response.data.result) {
                setUsuarioAutenticado(true);
                setSeccionActual('noticias'); // Establecer sección por defecto tras iniciar sesión
            } else {
                Swal.fire('Error', response.data.msg, 'error');
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            Swal.fire('Error', 'No se pudo iniciar sesión.', 'error');
        }
    };

    // Manejar el envío del formulario
    const handleSubmit = (event) => {
        event.preventDefault();
        iniciarSesion();
    };

    // Cambiar la sección actual en el panel de administración
    const cambiarSeccion = (seccion) => {
        setSeccionActual(seccion);
    };

    // Mostrar formulario de inicio de sesión si el usuario no está autenticado
    if (!usuarioAutenticado) {
        return (
            <div className="login-container">
                <h1>Iniciar Sesión</h1>
                <form onSubmit={handleSubmit}>
                    <input 
                        type="text" 
                        name="username" 
                        placeholder="Nombre de usuario" 
                        value={credenciales.username} 
                        onChange={handleInputChange} 
                    />
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Contraseña" 
                        value={credenciales.password} 
                        onChange={handleInputChange} 
                    />
                    <button type="submit">Iniciar Sesión</button>
                </form>
            </div>
        );
    }

    // Mostrar el panel de administración si el usuario está autenticado
    return (
        <div>
            <h1>Panel de Administración</h1>
            <div>
                <button onClick={() => cambiarSeccion('noticias')}>Noticias</button>
                {/* Activar estos botones cuando los componentes estén listos */}
                {/* <button onClick={() => cambiarSeccion('carrusel')}>Carrusel</button>
                <button onClick={() => cambiarSeccion('contacto')}>Contacto</button>
                <button onClick={() => cambiarSeccion('usuarios')}>Usuarios</button> */}
            </div>
            <div>
                {seccionActual === 'noticias' && <Noticias />}
                {/* Renderizar componentes correspondientes según la sección */}
                {/* {seccionActual === 'carrusel' && <Carrusel />}
                {seccionActual === 'contacto' && <Contacto />}
                {seccionActual === 'usuarios' && <Usuarios />} */}
            </div>
        </div>
    );
};

export default Admin;
