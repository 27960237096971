import React from 'react';
import Swal from 'sweetalert2';
import './Salud.css'; // Asegúrate de tener este archivo CSS para estilos
import imagenSalud1 from '../../../Images/Salud/analisis.jpeg';
import imagenSalud2 from '../../../Images/Salud/armazon.jpg';
import imagenSalud3 from '../../../Images/Salud/optometria.jpg';
import imagenSalud4 from '../../../Images/Salud/papanicolau.jpg';
import imagenSalud5 from '../../../Images/Salud/podologia.jpg';

function Salud() {
    const imagenes = [
        imagenSalud1,
        imagenSalud2,
        imagenSalud3,
        imagenSalud4,
        imagenSalud5,
    ];

    const abrirImagen = (imagen) => {
        Swal.fire({
            html: `<img src=${imagen} style="width:100%;" />`,
            width: '50%',
            showConfirmButton: false,
            background: 'transparent',
            padding: 0,
            border: 'none',
            heightAuto: false
        });
    };

    return (
        <div className="galeria-salud">
            {imagenes.map((imagen, index) => (
                <div key={index} className="tarjeta-salud" onClick={() => abrirImagen(imagen)}>
                    <img src={imagen} alt={`Imagen Salud ${index + 1}`} />
                </div>
            ))}
        </div>
    );
}

export default Salud;
