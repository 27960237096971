import './App.css';
import { Routes, Route} from 'react-router-dom';
import Header from './Componentes/Header/Header.js';
import Conac from './Componentes/Conac/Conac.js';
import Footer from './Componentes/Footer/Footer.js';
import Noticias from './Componentes/Noticias/Noticias.js';
import Somos from './Componentes/Somos/Somos.js';
import Directorio from './Componentes/Directorio/DirectorioMain/Directorio.js';
import Contacto from './Componentes/Contacto/Contacto.js';
import DatosAbiertos from './Componentes/DatosAbiertos/DatosAbiertos.js';
import Index from './Componentes/Index/Index.js';
import Admin from './Componentes/Admin/Admin.js';

function App() {

  return (
    <div>
      <Header/>
      <div className="main-content">
        <Routes>
          <Route path="/" element={
            <> 
              <Index/>
            </>
          }/>
          <Route path="/directorio" element={<Directorio/>}/>
          <Route path="/noticias" element={<Noticias/>}/>
          <Route path="/somos" element={<><Somos/></>}/>
          <Route path="/conac" element={<><Conac/></>}/>
          <Route path="/contacto" element={<><Contacto/></>}/>
          <Route path="/datos" element={<><DatosAbiertos/></>}/>
          <Route path="/Admin" element={<><Admin/></>}/>
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;

