import React from 'react';
import PrimerInforme from '../../../Documentos/Informes/Primer Informe de Gobierno.pdf'
import SegundoInforme from '../../../Documentos/Informes/Segundo Informe de Gobierno.pdf'
import logoprimero from '../../../Images/loginfo.png'
import logosegundo from '../../../Images/logosegundoinforme.png'
import './Informes.css'
function Informes() {
    return (
      <div className="informes-container">
        <div className="button_informe">
          <a href={PrimerInforme}>
            <button className="informe_button">
              <img src={logoprimero} alt="Primer Informe" />
            </button>
          </a>
        </div>
        <div className="button_informe">
          <a href={SegundoInforme}>
            <button className="informe_button">
              <img src={logosegundo} alt="Segudno Informe" />
            </button>
          </a>
        </div>
      </div>
    );
  };

export default Informes;
