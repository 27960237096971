import React, { useState, useEffect} from 'react';
import axios from '../../../AxiosConfig';
import Swal from 'sweetalert2';
import Pica from 'pica';

const AdministrarNoticias = () => {
    const [noticias, setNoticias] = useState([]);
    const [noticia, setNoticia] = useState({
        id: '',
        titulo: '',
        cuerpo: '',
        fecha:'',
        thumbnail:'',
        imagen: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNoticia({ ...noticia, [name]: value });
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const originalImage = e.target.files[0];
            console.log('Imagen original seleccionada:', originalImage);
    
            // Leer la imagen original como base64
            const reader = new FileReader();
            reader.onload = (event) => {
                const originalImageBase64 = event.target.result;
    
                // Crear un canvas para el thumbnail
                const canvas = document.createElement('canvas');
                const img = new Image();
                img.onload = () => {
                    // Aquí ajustas las dimensiones deseadas para tu thumbnail
                    const thumbnailWidth = 500;
                    const aspectRatio = img.naturalWidth / img.naturalHeight;
                    canvas.width = thumbnailWidth;
                    canvas.height = thumbnailWidth / aspectRatio;
    
                    // Redimensionar la imagen para crear el thumbnail
                    const pica = Pica();
                    pica.resize(img, canvas)
                        .then(() => pica.toBlob(canvas, 'image/jpeg'))
                        .then(thumbnailBlob => {
                            // Leer el thumbnail como base64
                            const thumbnailReader = new FileReader();
                            thumbnailReader.onload = (event) => {
                                const thumbnailBase64 = event.target.result;
    
                                // Aquí actualizas tu estado con ambas imágenes en base64
                                setNoticia({
                                    ...noticia,
                                    imagenBase64: originalImageBase64,
                                    thumbnailBase64: thumbnailBase64
                                });
                            };
                            thumbnailReader.readAsDataURL(thumbnailBlob);
                        });
                };
    
                // Iniciar la carga de la imagen original para el thumbnail
                img.src = originalImageBase64;
            };
    
            reader.readAsDataURL(originalImage);
        }
    };
    
    
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!noticia.titulo || !noticia.cuerpo || !noticia.imagen || !noticia.fecha) {
            Swal.fire('Error', 'Por favor, completa todos los campos y selecciona una imagen.', 'error');
            return;
        }
        const data = {
            titulo: noticia.titulo,
            cuerpo: noticia.cuerpo,
            fecha: noticia.fecha,
            imagenBase64: noticia.imagenBase64,
            thumbnailBase64: noticia.thumbnailBase64
        };
    
        try {
            const response = await axios.post('postNoticia.php', data);
            if (response.data.result) {
                Swal.fire('¡Éxito!', 'Noticia publicada correctamente.', 'success');
            } else {
                // Manejo de un resultado falso
                Swal.fire('Error', 'Hubo un problema al publicar la noticia.', 'error');
            }
            cargarNoticias();
        } catch (error) {
            console.error('Error al publicar la noticia:', error);
            Swal.fire('Error', 'Hubo un problema al publicar la noticia.', 'error');
        }
    };
    
    const handleEdit = async (id) => {
        try {
            const response = await axios.get(`getNoticia.php?id=${id}`);
            setNoticia(response.data.noticia);
        } catch (error) {
            console.error('Error al obtener la noticia:', error);
            Swal.fire('Error', 'No se encontró la noticia con el ID proporcionado.', 'error');
        }
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esta acción!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        if (result.isConfirmed) {
            try {
                let resultado = await axios.post('deleteNoticia.php', id);
                if(resultado.data.result){
                Swal.fire(
                    'Eliminada',
                    'La noticia ha sido eliminada.',
                    'success'
                );
                cargarNoticias();}
            } catch (error) {
                console.error('Error al eliminar la noticia:', error);
                Swal.fire('Error', 'Hubo un problema al eliminar la noticia.', 'error');
            }
        }
    };
    

    const cargarNoticias = async () => {
        try {
            const response = await axios.get('getNoticias.php');
            const noticiasOrdenadas = response.data.noticias.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
            setNoticias(noticiasOrdenadas);
        } catch (error) {
            console.error('Error al cargar las noticias:', error);
            Swal.fire('Error', 'No se pudieron cargar las noticias.', 'error');
        }
    };
    

        useEffect(() => {
            cargarNoticias();
        }, []);

    return (
        <div>
            <h2>Administrar Noticias</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="titulo"
                    placeholder="Título"
                    value={noticia.titulo}
                    onChange={handleChange}
                />
                <textarea
                    name="cuerpo"
                    placeholder="Cuerpo de la noticia"
                    value={noticia.cuerpo}
                    onChange={handleChange}
                />
                <input
                    type="date"
                    name="fecha"
                    value={noticia.fecha}
                    onChange={handleChange}
                />

                <input
                    type="file"
                    name="imagen"
                    onChange={handleImageChange}
                />
                <button type="submit">Publicar Noticia</button>
            </form>
            <div>
                <h3>Noticias Existentes</h3>
                {noticias.map((noticia) => (
                    <div key={noticia.id}>
                        <img 
                            className='Img_Thumbnail' 
                            src={axios.defaults.baseURL + 'Imagenes/Noticias/' + noticia.url} 
                            alt={noticia.id_imagen} 
                            style={{ width: '200px', height: 'auto' }}
                        />
                        <h4>{noticia.titulo}</h4>
                        <p>{noticia.cuerpo}</p>
                        <button onClick={() => handleEdit(noticia.id)}>Editar</button>
                        <button onClick={() => handleDelete(noticia.id)}>Eliminar</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdministrarNoticias;
