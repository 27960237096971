import React, { useState, useEffect } from 'react';
import bannerImage1 from '../../../Images/portada dif.png';
import './Banner.css';

function Banner() {
    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsSmall(true);
        }, 3000); // Cambia el estado después de 3 segundos

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`banner ${isSmall ? 'small' : ''}`}>
            <div className="fondos">
                <img className="sin_fondo" src={bannerImage1} alt="Banner 1" />
            </div> 
        </div>
    );
}

export default Banner;
