import React, { useState } from 'react';
import './Programas.css'; 
import imagenNutricion from '../../../Images/Servicios/horta.jpg'; 
import imagenAdulto from '../../../Images/Servicios/adulto.jpg';
import imagenFamilia from '../../../Images/Servicios/familia.jpg';
import imagenUris from '../../../Images/Servicios/uris.jpg';
import Swal from 'sweetalert2';
import imagenSwalAlimentacion from '../../../Images/Servicios/alimentacion.jpg'
import imagenSwalAdulto from '../../../Images/Servicios/adulto.jpeg'
import imagenSwalFamilia from '../../../Images/Servicios/bienestar.jpg'
import imagenSwalUris from '../../../Images/Servicios/asistencia.jpg'

function ProgramasDIF() {
  const [expanded, setExpanded] = useState(null);

  const toggleExpansion = (programa) => {
    setExpanded(expanded === programa ? null : programa);
  };
  const handleVerMas = (programa) => {
    let imagenSeleccionada;
    switch (programa) {
        case 'nutricion':
            imagenSeleccionada = imagenSwalAlimentacion;
            break;
        case 'adulto':
            imagenSeleccionada = imagenSwalAdulto;
            break;
        case 'familiar':
            imagenSeleccionada = imagenSwalFamilia;
            break;
        case 'discapacidad':
            imagenSeleccionada = imagenSwalUris;
            break;
            default:
              imagenSeleccionada = ''; 
              break;
    }

    Swal.fire({
      html: `<img src=${imagenSeleccionada} style="height:85%;" />`,
      width: '70%',
      showConfirmButton: false, // No mostrar el botón de confirmación
      background: 'transparent', // Fondo transparente
      padding: 0, // Sin relleno
      border: 'none', // Sin bordes
      heightAuto: false
    });
    
};
  return (
    <div className="servicios">
      <h2 className="titulo">Programas del DIF Municipal</h2>

      <div className={`programa ${expanded === 'nutricion' ? 'expanded' : ''}`}>
        <h3 onClick={() => toggleExpansion('nutricion')}>Alimentación y Nutrición Familiar <span className={`arrow ${expanded === 'nutricion' ? 'expanded' : ''}`}></span></h3>
        <div className="programa-content">
          <img src={imagenNutricion} alt="Alimentación y Nutrición" />
          <p>Mejorar los niveles nutricionales de los grupos más vulnerables de la población, implementando acciones aplicadas en 
            favor de las familias de escasos recursos, a través de los programas de la Coordinación</p>
            <button className='Button-vermas' onClick={() => handleVerMas('nutricion')}>Ver más</button>
        </div>
      </div>

      <div className={`programa ${expanded === 'adulto' ? 'expanded' : ''}`}>
        <h3 onClick={() => toggleExpansion('adulto')}>Atención al Adulto Mayor <span className={`arrow ${expanded === 'adulto' ? 'expanded' : ''}`}></span></h3>
        <div className="programa-content">
          <img src={imagenAdulto} alt="Atención al Adulto Mayor" />
          <p>Coordinar, promover, apoyar, fomentar las acciones públicas, estrategias y programas que se derivan de la política nacional
             a favor de las personas adultas mayores, protegiendo, ayudando y orientando a las mismas para encontrar soluciones a su problemática.</p>
             <button className='Button-vermas' onClick={() => handleVerMas('adulto')}>Ver más</button>
        </div>
      </div>

      <div className={`programa ${expanded === 'familiar' ? 'expanded' : ''}`}>
        <h3 onClick={() => toggleExpansion('familiar')}>Prevención y Bienestar Familiar <span className={`arrow ${expanded === 'familiar' ? 'expanded' : ''}`}></span></h3>
        <div className="programa-content">
          <img src={imagenFamilia} alt="Prevención y Bienestar Familiar" />
          <p>Desarrollar acciones de prevención que promuevan la salud mental de niños, adolescentes, jóvenes, adultos y adultos mayores a través de la ejecución de 
            programas como Atención Psicológica y Psiquiátrica, Menores en Riesgo y Situación de Calle, Atención al Menor y al Adolescente,
             Prevención de las Adicciones, Atención a la Familia y Atención a la Salud Mental de la Mujer, con el propósito de que tengan una vida plena y productiva.</p>
             <button className='Button-vermas' onClick={() => handleVerMas('familiar')}>Ver más</button>
        </div>
      </div>

      <div className={`programa ${expanded === 'discapacidad' ? 'expanded' : ''}`}>
        <h3 onClick={() => toggleExpansion('discapacidad')}>Atención a la Discapacidad <span className={`arrow ${expanded === 'discapacidad' ? 'expanded' : ''}`}></span></h3>
        <div className="programa-content">
          <img src={imagenUris} alt="Atención a la Discapacidad" />
          <p>Procurar acciones que promuevan el desarrollo integral de las familias y de grupos en situación de vulnerabilidad, contribuyendo a mejorar su calidad de vida, a través 
            de los programas preventivos y formativos que promuevan valores y encaucen el fortalecimiento del tejido social.</p>
            <button className='Button-vermas' onClick={() => handleVerMas('discapacidad')}>Ver más</button>
        </div>
      </div>
    </div>
  );
}

export default ProgramasDIF;
