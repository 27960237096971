import React from 'react';
import ProcuraduriaPDF from '../../../Documentos/Procuraduria/Servicios Procuraduría de protección niños 2022.pdf'
import logoProcu from '../../../Images/procu.png'
import './Procuraduria.css'

function Procuraduria() {
    return (
      <div className="procuraduria-container">
        <div className="button_procuraduria">
          <a href={ProcuraduriaPDF}>
            <button className="buttonprocu">
              <img src={logoProcu} alt="Procu" />
            </button>
          </a>
        </div>
      </div>
    );
  };

export default Procuraduria;
