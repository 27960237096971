import React, { useEffect, useState } from 'react';
import axios from '../../AxiosConfig';
import Swal from 'sweetalert2';
import './Noticias.css';

const Noticias = ({ limit }) => {
    const [noticias, setNoticias] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const noticiasPorPagina = 20;
    const totalPaginas = Math.ceil(noticias.length / noticiasPorPagina);
    const indiceUltimaNoticia = paginaActual * noticiasPorPagina;
    const indicePrimeraNoticia = indiceUltimaNoticia - noticiasPorPagina;
    const noticiasActuales = noticias.slice(indicePrimeraNoticia, indiceUltimaNoticia);

    const cambiarPagina = (numeroPagina) => {
        setPaginaActual(numeroPagina);
    };
    

    useEffect(() => {
        const fetchNoticias = async () => {
            try {
                const response = await axios.get('getNoticias.php');
                const noticiasOrdenadas = response.data.noticias.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
                setNoticias(noticiasOrdenadas);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchNoticias();
    }, []);

    const verNoticia = async (noticia) => {
        try {
            if (noticia.id) {
                const isMobile = window.innerWidth <= 768;
                const titleFontSize = isMobile ? '20px' : '30px';
                const titleHtml = `<div style="background-color: var(--rosa); color: var(--blanco); padding: 10px; border-radius: 5px; font-size: ${titleFontSize};">${noticia.titulo}</div>`;
                const width = isMobile ? '100%' : '90%';
    
                const contentHtml = `
                <div style="${isMobile ? '' : 'display: flex; align-items: center; overflow-y: auto;'}">
                    <div style="${isMobile ? 'width: 100%;' : 'width: 50%; display: flex; justify-content: center; align-items: center;'}">
                        <img src="${axios.defaults.baseURL + 'Imagenes/Noticias/' + noticia.url}" alt="Imagen de Noticia" 
                        style="max-width: 100%; max-height: 700px; object-fit: contain;">
                    </div>
                    <p style="text-align: justify; font-size: 20px; ${isMobile ? 'width: 100%;' : 'width: 50%; padding: 10px;'}">${noticia.cuerpo}</p>
                </div>`;
    
                Swal.fire({
                    title: titleHtml,
                    html: contentHtml,
                    width: width,
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: 'var(--morado)',
                    heightAuto: false,
                });
            }
        } catch (error) {
            console.error('Error al obtener la noticia:', error);
            Swal.fire({
                title: 'Error',
                text: 'No se encontró la noticia con el ID proporcionado.',
                icon: 'error',
            });
        }
    };
    
    
    return (
        <div className="noticias-container full-screen-containerNoticias">
            <div className="noticias">
                <h1>Últimas Noticias</h1>
                <p>Conoce las acciones que el DIF municipal está realizando para ti, unidos para servir.</p>
            </div>
            <div className="notas">
                {noticiasActuales.map(noticia => (
                    <div key={noticia.id} className="item_n">
                        <img className='Img_Thumbnail' src={axios.defaults.baseURL+'Imagenes/Noticias/'+ noticia.url} alt={noticia.id_imagen} />
                        <h4 className='Noticia_Title'>{noticia.titulo}</h4>
                        <button className='Noticia_Ver' onClick={() => verNoticia(noticia)}>Ver más</button>
                    </div>
                ))}
            </div>
            <div className="paginacion">
                {Array.from({ length: totalPaginas }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => cambiarPagina(index + 1)}
                        className={`pagina-boton ${paginaActual === index + 1 ? 'activo' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
    
    
};

export default Noticias;
