import React from 'react';
import Informes from './Informes/Informes';
import './Index.css'; 
import ProgramasDIF from './Programas/Programas';
import Banner from './Banner/Banner';
import Salud from './Salud/Salud';
import Procuraduria from './Procuraduria/Procuraduria'; 
import Carrusel from './Carrucel/Carrucel';

function Index() {
    return (
        <div className="index-container">
            <Banner/>
            <div className="texto-index">
                    <p>En el DIF de Zinacantepec tenemos como objetivo brindar asistencia social orientada a la protección de la infancia, las personas con discapacidad,
                        los adultos mayores, los grupos vulnerables y las familias Zinacantepequenses, mediante acciones que contribuyan a mejorar su condición social.</p>
            </div>
            <Carrusel/>
           <Informes />
           <ProgramasDIF/>
           <Salud/>
           <Procuraduria/>
           <div className="iframe_container">
                <iframe title='Facebook'
                    className="facebook-banner" 
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100076585917727&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId" 
                    width="340" 
                    height="500" 
                    style={{border: 'none', overflow: 'hidden'}} 
                    allowFullScreen={true} 
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                </iframe>
            </div>
        </div>
    );
}

export default Index;
