import React from 'react';
import './Footer.css'; 
import Logo_DIF from '../../Images/LOGODIF.png';
import QR_DIF from '../../Images/qr-code.png';


const Footer = () => {
    return (
        <div className="content_pie">
            <div className="info">
                <img  src={Logo_DIF} alt="LOGODIF"/>
                <p>Adolfo López Mateos 300, Barrio La Veracruz, 51350 San Miguel Zinacantepec, Méx. (722) 218 1777</p>
                <img style={{ width: '150px', padding: '10px' }} src={QR_DIF} alt="QR Code"/>
            </div>
        </div>
    );
};

export default Footer;
