import React, { useState } from 'react';
import './Contacto.css';
import axios from '../../AxiosConfig';
import Swal from 'sweetalert2';

const Contacto = () => {
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [comentario, setComentario] = useState('');

    const handleTelefonoChange = (e) => {
        const valor = e.target.value;
        // Permitir solo números
        if (/^\d*$/.test(valor) && valor.length <= 10) {
            setTelefono(valor);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (telefono.length !== 10) {
            Swal.fire({
                title: 'Error',
                text: 'El teléfono debe tener 10 dígitos.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return; 
        }
        try {
            // Asegúrate de reemplazar 'URL_DEL_SERVIDOR' con la URL correcta
            const response = await axios.post('postComentario.php', { nombre, correo, telefono, comentario });
            console.log(response)
            // Manejar respuesta exitosa
            Swal.fire({
                title: '¡Enviado!',
                text: 'Tu comentario fue enviado correctamente.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });

            // Limpiar el formulario
            setNombre('');
            setCorreo('');
            setTelefono('');
            setComentario('');
        } catch (error) {
            // Manejar error
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error, por favor intenta más tarde.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };

    return (
        <div className="contacto_container">
            <form className="contacto" onSubmit={handleSubmit}>
                <h1>Unidos para Servir</h1>
                <h2>Nombre:</h2>
                <input
                    type="text"
                    placeholder="Ingresa tu nombre"
                    name="nombre"
                    value={nombre}
                    required
                    onChange={(e) => setNombre(e.target.value)}
                />

                <h2>Correo:</h2>
                <input
                    type="email"
                    placeholder="Ingresa tu correo"
                    name="correo"
                    value={correo}
                    required
                    onChange={(e) => setCorreo(e.target.value)}
                />

                <h2>Teléfono:</h2>
                <input
                    type="tel"
                    placeholder="Ingresa tu teléfono"
                    name="telefono"
                    value={telefono}
                    required
                    onChange={handleTelefonoChange}
                    maxLength="10"
                    pattern="\d{10}" // Solo permite 10 dígitos numéricos
                    title="El teléfono debe tener 10 dígitos."
                />

                <h2>Escribe tu comentario:</h2>
                <textarea
                    placeholder="Ingresa un comentario"
                    name="comentario"
                    value={comentario}
                    required
                    onChange={(e) => setComentario(e.target.value)}
                ></textarea>

                <button className="btn_contacto" type="submit">Enviar</button>
            </form>
        </div>
    );
};

export default Contacto;
