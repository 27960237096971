import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig';
import { Accordion } from 'react-bootstrap';
import './Conac.css';

const CONAC = () => {
    const [carpetas, setCarpetas] = useState([]);

    useEffect(() => {
        const fetchCarpetas = async () => {
            try {
                const response = await axios.get('getConac.php');
                const datosProcesados = procesarDatos(response.data);
                setCarpetas(datosProcesados);
            } catch (error) {
                console.error('Error al cargar las carpetas:', error);
            }
        };
        fetchCarpetas();
    }, []);

    const procesarDatos = (datos) => {
        let resultado = [];
        const añosOrdenados = Object.keys(datos)
            .sort((a, b) => Number(b) - Number(a));
        
        añosOrdenados.forEach((clave) => {
            const entrada = procesarEntrada(clave, datos[clave]);
            resultado.push(entrada);
        });
        
        return resultado;
    };
    
    const procesarEntrada = (nombre, valor) => {
        if (Array.isArray(valor)) {
            return {
                nombre,
                tipo: 'carpeta',
                contenido: valor.map(archivo => ({
                    nombre: archivo,
                    tipo: 'archivo'
                }))
            };
        } else if (typeof valor === 'object' && valor !== null) {
            const contenido = Object.keys(valor).map(subclave => 
                procesarEntrada(subclave, valor[subclave])
            );
            return { nombre, tipo: 'carpeta', contenido };
        } else {
            return { nombre, tipo: 'archivo' };
        }
    };

    const renderizarAcordeonPorAño = (carpetas) => {
        return carpetas.map((carpeta, indexAno) => (
            <div key={indexAno}>
                <h3 className='Titulo_Año'>DIF-{carpeta.nombre}</h3>
                {renderizarContenido(carpeta.contenido, carpeta.nombre)}
            </div>
        ));
    };
    
    const renderizarContenido = (contenido, rutaActual = '') => {
        return (
            <Accordion defaultActiveKey="">
                {contenido.map((item, index) => {
                    const rutaCompleta = rutaActual ? `${rutaActual}/${item.nombre}` : item.nombre;
                    
                    if (item.tipo === 'carpeta') {
                        return (
                            <Accordion.Item eventKey={String(index)} key={index}>
                                <Accordion.Header>{item.nombre}</Accordion.Header>
                                <Accordion.Body>
                                    {Array.isArray(item.contenido) 
                                        ? renderizarContenido(item.contenido, rutaCompleta)
                                        : null}
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    } else {
                        return (
                            <div key={index}>
                                <a href={`${axios.defaults.baseURL}Documentos/CONAC/${rutaCompleta}`} target="_blank" rel="noopener noreferrer">
                                    {item.nombre}
                                </a>
                            </div>
                        );
                    }
                })}
            </Accordion>
        );
    };
    

    return (
        <div className="conac-container">
            {renderizarAcordeonPorAño(carpetas)}
        </div>
    );
};

export default CONAC;
